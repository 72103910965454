import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import SeoPageImg from "../../components/images/web/seoimage"

export default function seoPage() {
    return (
        <>
            <Layout>
                <SEO title="SEO" />
                <Display
                    title="SEO"
                    description="Let’s enhance your website rankings so you get found easily – that’s what SEO is all about. From mobile-first sites and a strong user experience, we’ve got strategies that work. "
                    prev="/web/web-development"
                    next="/web/blogs"
                    image={<SeoPageImg />}
                />
            </Layout>                
        </>
    )
}
